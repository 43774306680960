import React from 'react';
import Layout from '../../components/Layout';
import NavThree from '../../components/NavThree';
import SchoolsPageHeader from '../../components/SchoolsPageHeader';
import Footer from '../../components/Footer';
import CourseDetails from '../../components/CourseDetails';

const CoursePage = () => {
  return (
    <Layout pageTitle='School of Community Health | LUTH Schools'>
      <NavThree />
      <SchoolsPageHeader title='School of Community Health' />
      <CourseDetails title='School of Community Health' />
      <Footer />
    </Layout>
  );
};

export default CoursePage;
